.header-wrap {
  position: relative;
}

.link {
    text-decoration: none;
}

.logo_a {
    text-decoration: none !important;
}

.logoLabels {

    display: block;
    text-align: left;
    padding-left: 46px;
    padding-top: 12px;

    .logoMain {
        color: #676767 !important;
        text-decoration: none !important;
        font-size: 18px;
    }
    .logoSubtitle {
        white-space: nowrap;
        color: #eb008c !important;
        text-decoration: none !important;
        font-size: 14px;
    }
}
.navbar-fixed-top {
  position: fixed;

  right: 0;

  left: 0;

  z-index: 999;
}

.header-wrapper {
  width: 100%;
  margin: 0px auto 0;
  padding-top: 10px;
  display: block;
  justify-content: center;
  background: #fff;
  height: 128px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 9px 0px;
  transition: all 0.5s ease-in-out 0s;
}

.header-wrapper.extended {
  height: 134px;
}

.header-left {
  display: block;
  width: 100%;
}

.first-line {
  display: block;
  cursor: pointer;
  height: 75px;
}

.first-line p {
  font-size: 13px;
  color: rgb(84, 84, 84);
}

.logoWrapper {
    padding-right: 46px;
    float: left;
}

#logo {
  height: 46px;
  position: absolute;
  padding-top: 6px;
}

.select-city {
  width: 171px;
  height: 34px;
  background-color: #f4f4f4;
  margin-left: 34px;
}

.select-experience {
  background-color: #f4f4f4;
  margin-left: 5px;
  display: flex;
}

.select-experience input {

  border: none;
  background: #f4f4f4;
  width: 580px;
  height: 32px;
  outline: none;
  padding-left: 25px;
}

.select-experience i {
  color: #cbcbcb;
  font-size: 15px;
  padding-top: 12px;
  padding-right: 20px;
}

.search-line-wrapper {
    display: flex;
    padding-top: 14px;
    transition: opacity 0.3s ease-in-out;
}

.extended .first-line {
    padding-top: 16px;
    padding-bottom: 0px;
}

.iwnav-mainnav-level1 {
    box-sizing: border-box;

    background-color: transparent;
    border: 0;
    font-family: sans-serif;
    font-size: 100%;
    font-style: inherit;
    font-weight: inherit;
    margin: 0;
    outline: 0;
    padding: 0;
    vertical-align: baseline;

    border-top: 2px solid #eb008c;
    height: 48px;
    transition-delay: .1s;
    transition-duration: 0;
    transition-property: background,border-top-color;
    align-items: center;
    display: flex;
    justify-content: space-evenly;

}

.iwnav-mainnav-level1:hover {
    background: #fff;
    border-top-color: #eb008c;
}

.iwnav-mainnav-level1:hover span {
    color: #eb008c;

}

.iwnav-mainnav-level1 span {
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    text-decoration: none;

    align-items: center;
    display: flex;
    height: 44px;
    justify-content: space-evenly;
    padding: 0 16px;
    text-align: right;
    white-space: nowrap;
    width: 100%;


}

.iwnav-channels {
        background-color: transparent;
        border: 0;
        font-family: sans-serif;
        font-size: 100%;
        font-style: inherit;
        font-weight: inherit;
        margin: 0;
        outline: 0;
        padding: 0;
        vertical-align: baseline;

    align-items: normal;
    display: flex;
    height: 48px;
    position: relative;
    list-style-type: none;
    box-sizing: border-box;
    list-style: none;

}

.second-line-wrapper {
    width: 100%;
    display: inline-flex;
        justify-content: space-between;
    /* display: flex; */
    max-width: 1180px;
    padding-left: 20px;
    padding-right: 0;
    height: 48px;
}

.iwnav-current {
    background-color: #a30c61;
    border-top-color: #a30c61;
    transition-duration: .33s;
    transition-property: background-color,border-top-color;
}

.second-line {
  z-index: 3;
  display: block;
  cursor: pointer;
  padding-top: 0px;
  background: #eb008c;
  justify-content: space-between;
}

.second-line p {
    color: white;
    font-size: 15px;
    margin-right: 30px;
    font-weight: 600;
    margin-top: 17px;
}



.second-line p:hover {
  color: #cddeff;
}

.best-picked {
  display: flex;
}

.support {
  display: flex;
  margin-left: 120px;
  justify-content: flex-end;

}

.arrow-down {
  padding-left: 5px;
}

.header-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-left: 25px;

      justify-content: flex-end;
}

.sign-in {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
}

.sign-in-user {
  color: #e8e8e8;
  font-size: 20px;
}

.account-img img {
  width: 20px;
  border-radius: 50%;
}

.sign-in p {

  margin-top: 0px;
  margin-bottom: 6px;
}

.header-right p {
  color: #545454;
  font-size: 13px;
}

.header-right a {
    display: inline-block;

}

.download-app {
  display: flex;
  align-items: center;
  margin-top: -3px;
  min-width: 120px;
  justify-content: flex-end;
  cursor: pointer;
}

.download-app p {
  margin-left: 5px;
}

/* Slideshow */

#slideshow {
  position: relative;
  height: 450px;
}

#slideshow > .img-crop {
  position: absolute;
}

.test {
  font-family: Avenir, proxima-nova, arial, sans-serif;
  color: #545454;
}

/* Search Bar */

.search-bar-div--inactive {
pointer-events: none;
    margin-top: -46px !important;
    z-index: -1;
    opacity: 0;
    height: 46px;
}
.search-bar-div--active {
    margin-top: 6px !important;
    z-index: 1 !important;
    opacity: 1;
}
.search-bar-div {
  transition: all 0.5s;
  z-index: 2;
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: -28px;
}

.search-bar {
  position: relative;
  display: flex;
  justify-content: center;
}

.select-city-large {
  width: 216px;
  height: 54px;
  border-radius: 3px;
  box-shadow: 0 5px 22px 0 rgba(0, 0, 0, 0.2);
  background: white;
  cursor: pointer;
  display: flex;
}
.select-city-large.exl {
    width: 360px;
}
.select-city-large i {
  color: #9a9a9a;
  padding-top: 20px;
  padding-left: 30px;
  font-size: 15px;
}

.select-experience-large {
  border-radius: 3px;
  box-shadow: 0 5px 22px 0 rgba(0, 0, 0, 0.15);
  margin-left: 5px;
  background: white;
}

.select-experience-large input {
  border: none;
  font-size: 13px;
  padding-left: 20px;
  width: 280px;
  height: 52px;
  outline: none;
  -webkit-transition: width 0.3s ease-out;
  -moz-transition: width 0.3s ease-out;
  -o-transition: width 0.3s ease-out;
  transition: width 0.3s ease-out;
}

.select-experience-large input:focus-within {
  width: 450px;
}

.select-experience-large i {
  padding-right: 20px;
  color: #cbcbcb;
}

#go-filter {
padding-left: 18px;
padding-right: 18px;
  font-size: 14px;
  line-height: 22.68px;
  font-weight: 500;

  height: 54px;
  border-radius: 3px;
  border: none;
  outline: none;
  color: white;
  background: linear-gradient(    -45deg,    #eb008c,    #5779bf,    #72a6c9,    #eb008c,    #eb008c,    #eb008c,    #5779bf  );
  background-size: 400% 400%;
  -webkit-animation: Gradient 5s ease infinite;
  -moz-animation: Gradient 5s ease infinite;
  animation: Gradient 5s ease infinite;
  margin-left: 5px;
  cursor: pointer;
}

#go {
  font-size: 16.2px;
  line-height: 22.68px;
  font-weight: 500;
  width: 110px;
  height: 54px;
  border-radius: 3px;
  border: none;
  outline: none;
  color: white;
  background: linear-gradient(    -45deg,    #eb008c,    #5779bf,    #72a6c9,    #eb008c,    #eb008c,    #eb008c,    #5779bf  );
  background-size: 400% 400%;
  -webkit-animation: Gradient 5s ease infinite;
  -moz-animation: Gradient 5s ease infinite;
  animation: Gradient 5s ease infinite;
  margin-left: 5px;
  cursor: pointer;
}

@-webkit-keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@-moz-keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.search-bar-div--filter {
    z-index: 1 !important;
}